import React from 'react'
import Content from '../components/content'
import AppBrowser from '../components/appointment-browser'
import titleImg from '../images/bg10.jpg'

const PageAppBrowser = () => (
  <Content title='Appointment browser' tagline="Start booking(including vaccination) by clicking an appointment start time" titleImg={titleImg}>
    <AppBrowser/>
  </Content>
)

export default PageAppBrowser